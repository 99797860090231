
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    enableItemsPerPageDropdown: { type: Boolean, default: true },
    rowsPerPage: {
      type: Number,
      default: parseInt(localStorage.getItem("limit") || "5"),
    },
    setItemsPerPage: { type: Function, requierd: true },
    page: { type: Number, required: true },
    currentPageChange: { type: Function, requierd: true },
    total: { type: Number, required: true },
    currentPage: { type: Number, default: 1 },
    hasBackground: { type: Boolean, default: true },
    pagesArray: {
      type: Array,
      default: () => [5, 10, 25, 50, 75, 100],
    },
  },
  components: {},
  setup() {
    return {};
  },
});
