import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b19904a0"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start",
  id: "kt_customers_table_length"
}
const _hoisted_2 = {
  key: 0,
  class: "dataTables_length"
}
const _hoisted_3 = ["v-model", "value"]
const _hoisted_4 = ["value", "label"]
const _hoisted_5 = { class: "col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end pagination-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_pagination = _resolveComponent("el-pagination")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.enableItemsPerPageDropdown)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("label", null, [
              _createElementVNode("select", {
                name: "kt_customers_table_length",
                "v-model": _ctx.rowsPerPage,
                value: _ctx.rowsPerPage,
                class: "form-select form-select-sm form-select-solid cursor-pointer",
                onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.setItemsPerPage && _ctx.setItemsPerPage(...args)))
              }, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.pagesArray, (item, index) => {
                  return (_openBlock(), _createElementBlock("option", {
                    key: index,
                    value: item,
                    label: `${item}`
                  }, _toDisplayString(item), 9, _hoisted_4))
                }), 128))
              ], 40, _hoisted_3)
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_component_el_pagination, {
        "v-model:current-page": _ctx.page,
        onCurrentChange: _ctx.currentPageChange,
        "page-size": _ctx.rowsPerPage,
        layout: "prev, pager, next",
        total: _ctx.total,
        "hide-on-single-page": true,
        background: _ctx.hasBackground
      }, null, 8, ["v-model:current-page", "onCurrentChange", "page-size", "total", "background"])
    ])
  ], 64))
}